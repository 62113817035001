<template>
  <div>
    <div class="navbar" style="min-height: 2rem">
      <a href="/inicio">
        <img src="../../../public/pack_online_branco.png" alt="Alterdata software" />
      </a>
      <div class="navbar-end" v-if="!this.$root.erroAcesso">
        <div class="navbar-item">
          <div class="mr-6">
            <SinetaCentralNotificacoes
              @redirecionamentoDeRota="emitirEventoRedirecionamentoDeRota"
              aplicacao="packonline-phd"
              :link-phd="linkPhd"
            />
          </div>
          <AjudaModal/>
          <div class="field is-grouped ml-6">
            <config-menu
              @redirecionamentoDeRota="emitirEventoRedirecionamentoDeRota"
              />
          </div>
        </div>
      </div>
    </div>
    <div class="navbar-space"></div>
  </div>
</template>
<script>
import packIco from '../../icos/pack.png'
import ConfigMenu from '../ConfigMenu/index.vue'
import AjudaModal from '../alt-component/AjudaModal/index.vue'
import SinetaCentralNotificacoes from '@packonline/packonline-reutilizaveis-frontend/components/centralDeNotificacoes/index.vue'
import { env } from '@/env.js'

export default {
  data: function () {
    return {
      packIco: packIco,
      linkPhd: env.phd
    }
  },
  components: {
    'config-menu': ConfigMenu,
    SinetaCentralNotificacoes,
    AjudaModal
  },

  methods: {

    emitirEventoRedirecionamentoDeRota () {
      this.$emit('redirecionamentoDeRota')
    }
  }
}
</script>

<style  scoped>
.navbar {
  height: 35px;
  width: 100%;
  background-color: #a82339;
  text-anchor: start;
  color: white;
  z-index: 1001;
  position: absolute;
}

img {
  margin-left: 7px;
  margin-top: 5px;
  width: 190px;
}

.navbar-space {
  height: 35px;
}
</style>
