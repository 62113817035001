import { Service } from './service'

export class WphdService extends Service {
  async patch (url, dto) {
    const init = {
      headers: this.headers,
      method: 'PATCH',
      body: JSON.stringify(dto)
    }
    return fetch(`${this.api}${url}`, init).then(response => response)
  }
}

export const wphdService = new WphdService()
