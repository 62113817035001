<template>
  <header>
    <navbar @redirecionamento-de-rota="verificarSeHaDadosAlteradosParaExibirModalEDirecionarRota"/>
  </header>
  <lista-toast-aviso :listaToast="listaNotificacao" />
  <main class="is-flex flex-direction-column is-flex-grow-1" style="overflow: hidden; position: relative">
    <menu-lateral @redirecionamento-de-rota="verificarSeHaDadosAlteradosParaExibirModalEDirecionarRota" v-if="!this.erroAcesso" active-system="phd" :env="env" :hide-system="hideSystem"/>
    <div class="container is-fluid p-0 m-0" style="overflow: auto; overflow-x: hidden;">
      <router-view />
      <ModalMessage
        :active="mostrarModalDadosAlterados ? true : false"
        message="Ao sair do cadastro sem gravar, suas alterações serão perdidas. Qual ação você deseja realizar?"
        style="z-index: 99999"
        @close="fecharModalDadosAlteradosELimparRotaDoSessionStorage"
      >
        <template #footer>
          <div class="is-flex is-justify-content-center">
            <div class="buttons">
              <Button caption="Gravar e sair" type="primary" data-group="btnGravarESair" @click="parametrosModalSalvarMudancas.eventoSalvar" />
              <Button caption="Sair sem gravar" type="secondary" data-group="btnSairSemGravar" @click="parametrosModalSalvarMudancas.eventoSair" />
              <Button caption="Permanecer no cadastro" type="secondary" data-group="btnPermanecer" @click="fecharModalDadosAlteradosELimparRotaDoSessionStorage" />
            </div>
          </div>
        </template>
      </ModalMessage>
    </div>

  </main>
    <div
      v-if="paramentrosQuickView.componenteSlotQuickView ? true : false"
      :style="paramentrosQuickView.estilo"
    >
    <QuickView
      :titulo-tela="paramentrosQuickView.tituloTela"
      :titulo-botao-fechar="paramentrosQuickView.tituloBotaoFechar"
      :altura-quick-view="paramentrosQuickView.alturaQuickView"
      :ocultar-botao-fechar="paramentrosQuickView.ocultarBotaoFechar"
      :ocultar-botao-gravar="paramentrosQuickView.ocultarBotaoGravar"
      :minimizado="paramentrosQuickView.minimizado"
      :botao-salvar-primary="paramentrosQuickView.botaoSalvarPrimary"
      :p-continuarcadastrando="paramentrosQuickView.continuarCadastrando"
      :ocultar-botao-continuar-cadastrando="paramentrosQuickView.ocultarBotaoContinuarCadastrando"
      :minimizavel="paramentrosQuickView.minimizavel"
      :titulo-botao-gravar="paramentrosQuickView.tituloBotaoGravar"
      :largura-quick-view="paramentrosQuickView.larguraQuickView"
      :botao-gravar-desativado="paramentrosQuickView.botaoGravarDesativado"
      :atalho-botao-gravar="paramentrosQuickView.atalhoBotaoGravar"
      :atalho-botao-fechar="paramentrosQuickView.atalhoBotaoFechar"
      @salvar-quick-view="paramentrosQuickView.eventoSalvar()"
      @fechar-quick-view="_fecharQuickView()"
      @minimizar-quick-view="_minimizarQuickView()"
      @continuar-cadastrando="paramentrosQuickView.continuarCadastrando = $event"
    >
      <component :is="paramentrosQuickView.componenteSlotQuickView"></component>
    </QuickView>
    </div>
</template>
<script>
import { readonly } from 'vue'
import Navbar from './components/Navbar/index.vue'
import ListaToastAviso from '@packonline/packonline-reutilizaveis-frontend/components/ListaToastAviso/'
import QuickView from './components/QuickView/quickView.vue'
import ErroAcesso from './ErroAcesso.vue'
import modalSalvarMudancas from './components/ModalSalvarMudancas/index.vue'
import Modal from '@alterdata/component-vue/Modal'
import ModalMessage from '@alterdata/component-vue/ModalMessage/index.vue'
import Button from '@alterdata/component-vue/Button/index.vue'
import MenuLateral from '@packonline/packonline-reutilizaveis-frontend/components/MenuLateral/index.vue'
import { env } from './env'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys.js'
import { mixinShortKeysGlobal } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-short-keys-global'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao.js'
import { mixinEnter } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-enter.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { wphdService } from '@/WphdService.js'

export default {
  components: {
    Navbar,
    ListaToastAviso,
    QuickView,
    ErroAcesso,
    Modal,
    'modal-salvar-mudancas': modalSalvarMudancas,
    ModalMessage,
    Button,
    MenuLateral
  },
  mixins: [mixinShortKeysGlobal, mixinModalSaida, mixinEnter, mixin],
  provide () {
    return {
      rootData: readonly(this.$data),
      setRootData: this.setRootData,
      adicionarNotificacao: this.adicionarNotificacao,
      removerNotificacao: this.removerNotificacao
    }
  },
  data () {
    return {
      anoAtual: new Date().getFullYear(),
      textoParaModalSalvarMudancas: null,
      mostrarModalDadosAlterados: false,
      rotaPara: null,
      dadosAlterados: false,
      usuarioLogado: this._retornarUsuarioLogado(),
      erroAcesso: false,
      listaNotificacao: [],
      paramentrosQuickView: {
        componenteSlotQuickView: null,
        eventoSalvar: Function,
        eventoCancelar: Function,
        alturaQuickView: '81vh',
        tituloTela: String,
        tituloBotaoFechar: 'Fechar',
        ocultarBotaoFechar: false,
        ocultarBotaoGravar: false,
        atalhoBotaoFechar: '',
        atalhoBotaoGravar: '',
        ocultarBotaoContinuarCadastrando: true,
        minimizado: false,
        estilo: 'position: fixed; top: 35px; right: 0px; z-index: 1002;',
        botaoSalvarPrimary: false,
        continuarCadastrando: false,
        impedirFechamentoDoQuickViewAoCancelar: false
      },
      estiloInicialQuickViewAntesDeMinimizar: null,
      parametrosModalSalvarMudancas: {
        textoParaModalSalvarMudancas1: '',
        textoParaModalSalvarMudancas2: '',
        eventoSalvar: Function,
        eventoSair: Function
      },
      env: env,
      shortkeys: shortkeys,
      sectionAtual: '',
      hideSystem: [],
      verificandoDadosAlterados: false
    }
  },
  methods: {
    setRootData (callback) {
      callback(this.$data)
    },
    _minimizarQuickView () {
      this.paramentrosQuickView.minimizado =
        !this.paramentrosQuickView.minimizado
      if (this.paramentrosQuickView.minimizado) {
        this.estiloInicialQuickViewAntesDeMinimizar =
          this.paramentrosQuickView.estilo
        this.paramentrosQuickView.estilo =
          'position: fixed;top: 85%;right: 15px;width: 40%;z-index: 1002;'
      } else {
        this.paramentrosQuickView.estilo =
          this.estiloInicialQuickViewAntesDeMinimizar
      }
    },
    _fecharQuickView () {
      this.paramentrosQuickView.eventoCancelar()
      if (!this.paramentrosQuickView.impedirFechamentoDoQuickViewAoCancelar) {
        this.paramentrosQuickView.componenteSlotQuickView = null
        this.paramentrosQuickView.minimizado = false
      }
    },
    quickViewVisivel () {
      return !!this.$root.paramentrosQuickView.componenteSlotQuickView
    },
    selecionarBotaoModalSaida () {
      const botaoSaida = document.querySelector('button.button[data-group="btnPermanecer"]')
      if (botaoSaida) {
        botaoSaida.classList.add('prevent-tab')
        botaoSaida.focus()
      }
    },
    _retornarUsuarioLogado () {
      const cookies = ' ' + document.cookie
      const key = ' ' + 'Usuario' + '='
      const start = cookies.indexOf(key)

      if (start === -1) return ''

      const pos = start + key.length
      const last = cookies.indexOf(';', pos)

      if (last !== -1) return cookies.substring(pos, last)

      return cookies.substring(pos)
    },

    fecharModalDadosAlteradosELimparRotaDoSessionStorage () {
      this.mostrarModalDadosAlterados = false
      sessionStorage.removeItem('rotaClicadaNoMenuLateral')
    },

    verificarSeHaDadosAlteradosParaExibirModalEDirecionarRota () {
      this.verificandoDadosAlterados = true

      this.$nextTick(() => {
        if (this.dadosAlterados) {
          this.mostrarModalDadosAlterados = true
        } else {
          if (sessionStorage.getItem('rotaClicadaNoMenuLateral')) {
            const rotaClicadaNoMenuLateral = sessionStorage.getItem('rotaClicadaNoMenuLateral')
            const rotaAtual = this.$route.path

            sessionStorage.removeItem('rotaClicadaNoMenuLateral')
            if (rotaClicadaNoMenuLateral === rotaAtual) {
              location.reload()
            } else {
              this.$router.push(`${rotaClicadaNoMenuLateral}`)
            }
          }
        }
        this.verificandoDadosAlterados = false
      })
    },
    async retornaSistemasUtilizados () {
      setTimeout(() => {
        utils.retornaVinculosESistemasParaOcultar(env).then((response) => {
          if (response && Object.prototype.hasOwnProperty.call(response, 'hideSystem')) {
            this.hideSystem = response.hideSystem
          } else {
            this.hideSystem = false
          }
        }).catch(error => {
          console.error('Error fetching data: ', error)
          this.hideSystem = false
        })
      }, 200)
    },
    async executaPatchDaTabelaEmpresa () {
      try {
        await wphdService.patch('/api/v1/gerais/patch-sistema')
      } catch (error) {
      }
    }
  },
  mounted: async function () {
    ;(function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          ;(c[a].q = c[a].q || []).push(arguments)
        }
      t = l.createElement(r)
      t.async = 1
      t.src = 'https://www.clarity.ms/tag/' + i
      y = l.getElementsByTagName(r)[0]
      y.parentNode.insertBefore(t, y)
    })(window, document, 'clarity', 'script', 'dxsv26htv8')
    this.$nextTick(async () => {
      setTimeout(async () => {
        try {
          await this.retornaSistemasUtilizados()
        } catch (error) {
          this.hideSystem = false
        }
        await this.executaPatchDaTabelaEmpresa()
      }, 200)
    })
  },
  created () {
    window.addEventListener('keydown', this.handleKeyDown)
  },
  unmounted () {
    window.removeEventListener('keydown', this.handleKeyDown)
  },
  computed: {
    modalSaida () {
      return this.$root.mostrarModalDadosAlterados
    }
  },
  watch: {
    '$route.fullPath' () {
      this.paramentrosQuickView.ocultarBotaoContinuarCadastrando = true
    },
    mostrarModalDadosAlterados (val) {
      if (val) {
        this.sectionAtual = this.section
        const section = 'modalDeSaida'
        this.setActiveSection('modalDeSaida')
        this.addShortcut(section, shortkeys.KEY_ESC, () => {
          this.mostrarModalDadosAlterados = false
        })
        this.$nextTick(() => {
          this.selecionarBotaoModalSaida()
        })
      } else {
        this.setActiveSection(this.sectionAtual)
      }
    }
  }
}
</script>
