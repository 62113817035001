<template>
  <div class="card" style="width: 55%;margin-left: 20%;">
    <div class="notification" style="background-color: lightgoldenrodyellow;">
      Não conseguimos obter as informações de acesso. Por favor, autentique-se novamente!
    </div>
  </div>
</template>

<script>

export default {
  mounted () {
    this.$root.erroAcesso = true
  }
}
</script>
