/* global fetch */
import { env } from './env.js'

export class Service {
  constructor () {
    // this.api = 'http://localhost:8080'
    this.api = env.urlApi
    this.JWT = this._retornarJWT()
    this.headers = {
      'Content-Type': 'application/json',
      Authorization: this.JWT
    }
  }

  async get (url, id) {
    const init = {
      headers: this.headers
    }
    let response = null
    if (!id) {
      response = await fetch(this.api + url, init)
    } else {
      response = await fetch(this.api + url + '/' + id, init)
    }

    if (response.ok) {
      return response.json()
    }
    if (response.status === 401) {
      window.location.reload(true)
    }
    let erro
    await response.json().then(data => {
      erro = data
      if (erro.message === 'Inconsistent tenant information') {
        window.location.href = '/erroAcesso'
      }
    })
    throw new Error(erro.message)
  }

  async delete (url, item, id) {
    const init = {
      headers: this.headers,
      method: 'DELETE'
    }

    let idDelete = null
    if (id) {
      idDelete = id
    } else {
      idDelete = item.id
    }

    const response = await fetch(this.api + url + '/' + idDelete, init)
    if (response.ok) {
      return response
    }

    let erro
    await response.json().then(data => {
      erro = data
      if (erro.message === 'Inconsistent tenant information') {
        window.location.href = '/erroAcesso'
      }
    })
    throw new Error(erro.message)
  }

  async post (url, dto = null) {
    if (dto) {
      delete dto.id
    }

    const init = {
      headers: this.headers,
      method: 'POST',
      body: dto ? JSON.stringify(dto) : null
    }

    const response = await fetch(this.api + url, init)
    if (response.ok) {
      return response
    }

    let erro
    await response.json().then(data => {
      erro = data
      if (erro.message === 'Inconsistent tenant information') {
        window.location.href = '/erroAcesso'
      }
    })
    throw new Error(erro.message)
  }

  async patch (url, dto, id) {
    const init = {
      headers: this.headers,
      method: 'PATCH',
      body: JSON.stringify(dto)
    }
    let idPatch = null
    if (id) {
      idPatch = id
    } else {
      idPatch = dto.id
    }

    const response = await fetch(`${this.api}${url}/${idPatch}`, init)
    if (response.ok) {
      return response
    }

    let erro
    await response.json().then(data => {
      erro = data
      if (erro.message === 'Inconsistent tenant information') {
        window.location.href = '/erroAcesso'
      }
    })
    throw new Error(erro.message)
  }

  async save (url, dto, id) {
    let idPatch = null
    if (id) {
      idPatch = id
    } else {
      idPatch = dto.id
    }
    if (idPatch) {
      return this.patch(url, dto, idPatch)
    }
    return this.post(url, dto)
  }

  _retornarJWT () {
    const cookies = ' ' + document.cookie
    const key = ' ' + 'JWT' + '='
    const start = cookies.indexOf(key)

    if (start === -1) return ''

    const pos = start + key.length
    const last = cookies.indexOf(';', pos)

    if (last !== -1) return cookies.substring(pos, last)

    return cookies.substring(pos)
  }
}

export const service = new Service()
