 <template>
  <div id="modalConfirmacao">
    <Modal
      title="Atenção"
      :active="this.showModal"
      @close="this.fecharSemMudarRota"
      size="small"
    >
      <div>
        <span>
          <label id="textoAviso" :textContent="this.textoConfirmacao1"></label>
          <br />
          <label id="textoAviso" :textContent="this.textoConfirmacao2"></label>
          <slot name="new-textoConfirmacao"></slot>
        </span>
      </div>
      <template v-slot:footer>
        <div class="buttons">
          <button
            id="btnOk"
            class="button is-primary"
            @click="this.$emit('modalSalvarMudancasBotaoSalvar')"
          >
            Gravar
          </button>
          <button
            id="btnCancel"
            class="button"
            @click="this.fecharSemMudarRota"
          >
            Permanecer
          </button>
          <slot name="new-button"></slot>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from '@alterdata/component-vue/Modal/index.vue'

export default {
  name: 'confirmacao',
  showModal: true,
  props: {
    textoConfirmacao1: String,
    textoConfirmacao2: String,
    value: Boolean
  },
  components: { Modal },

  parametrosModalSalvarMudancas: {
    eventoSalvar: Function,
    eventoSair: Function
  },
  methods: {
    fecharSemMudarRota () {
      this.$root.mostrarModalDadosAlterados = false
    }
  },
  watch: {
    value (val) {
      if (val) this.$refs.dialog.show()
      else this.$refs.dialog.close()
    }
  },
  created () {
    this.showModal = this.$root.dadosAlterados
  }
}
</script>
<style scoped>
#textoAviso {
  padding-bottom: 0rem;
  margin: 0;
  font-size: 1.2em;
}
#modalConfirmacao {
  width: 300px;
}
#btnOk {
  width: 100px;
}
#btnCancel {
  width: 100px;
}
</style>
