<template>
  <div
    class="dropdown is-right is-active"
    @focusout="this.verificarSeHaModalAtivo()"
    tabindex="0"
  >
    <div class="dropdown-trigger">
      <a style="color: white" @click="this.abrirFecharConfig">
        <span class="icon is-medium">
          <em class="fas fa-cog"></em>
        </span>
      </a>
    </div>
    <div
      v-if="this.configVisivel"
      class="dropdown-menu"
      id="dropdown-menu-config"
      role="menu"
    >
      <div class="dropdown-content" style="padding: 5px">
        <div class="dropdown-item">
          <div class="columns">
            <div class="column">
              <div class="columns is-multiline">
                <div class="column is-12" style="font-size: medium">
                  <strong>Configurações</strong>
                </div>
                <a @click="this.exibirConfig" class="column is-12"
                  >Envio de e-mails</a
                >
                <a data-group="informacoes-do-contador" @click="this.exibirConfig" class="column is-12"
                  >Informações do contador</a
                >
                <a @click="this.exibirConfig" class="column is-12"
                  >Cadastro de usuários</a
                >
                <a @click="this.exibirConfig" class="column is-12"
                  >Configurações gerais</a
                >
                <a @click="this.exibirConfig" class="column is-12"
                  >Integração eContador</a
                >
                <a class="column is-12" @click="this.exibirConfig"
                >Relatórios contábeis</a>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <footer class="is-flex is-justify-content-center pb-3">
          <a style="color: blue" @click="this.configVisivel = false"
            >Mostrar menos</a
          >
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      configVisivel: false
    }
  },
  methods: {
    abrirFecharConfig () {
      this.configVisivel = !this.configVisivel
    },
    exibirConfig (e) {
      switch (e.target.innerText) {
        case 'Integração eContador':
          this.irParaConfiguracoes('integracaoecontador')
          this.configVisivel = false
          break
        case 'Envio de e-mails':
          this.irParaConfiguracoes('smtp')
          this.configVisivel = false
          break
        case 'Informações do contador':
          this.irParaConfiguracoes('infcontador')
          this.configVisivel = false
          break
        case 'Cadastro de usuários':
          this.irParaConfiguracoes('usuario')
          this.configVisivel = false
          break
        case 'Configurações gerais':
          this.irParaConfiguracoes('gerais')
          this.configVisivel = false
          break
        case 'Relatórios contábeis':
          this.irParaConfiguracoes('relatorios')
          this.configVisivel = false
          break
      }
    },
    irParaConfiguracoes (param) {
      const rota = `/configuracoes/${param}`
      sessionStorage.setItem('rotaClicadaNoMenuLateral', rota)
      this.$emit('redirecionamentoDeRota')
    },
    verificarSeHaModalAtivo () {
      const modal = document.querySelectorAll('.modal')[0]
      if (!modal) {
        this.configVisivel = false
      }
    }
  },
  updated () {
    if (this.configVisivel) {
      const dropdown = document.querySelector('#dropdown-menu-config')
      dropdown.focus()
    }
  }
}
</script>

<style scoped>
a {
  color: black;
}

*,
*:focus,
*:hover {
  outline: none;
}
</style>
