import { createRouter, createWebHistory } from 'vue-router'

const Home = () => import('../views/Home/index.vue')
const abaInicio = () => import('../views/Menu/abaInicio.vue')
const Cadastros = () => import('../views/Menu/abaCadastros.vue')
const Contabil = () => import('../views/Menu/abaContabil.vue')
const Empresa = () => import('../views/Empresa/index.vue')
const Indexador = () => import('../views/Indexador/index.vue')
const IndexadorCotacao = () => import('../views/IndexadorCotacao/index.vue')
const Feriados = () => import('../views/Feriados/index.vue')
const Irrf = () => import('../views/Irrf/index.vue')
const PlanosDeContas = () => import('../views/PlanosDeContas/index.vue')
const Socios = () => import('../views/Socios/index.vue')
const Configuracoes = () => import('../views/Configuracoes')
const ErroAcesso = () => import('../ErroAcesso.vue')
const ImpressaoRelatorioSocios = () => import('../views/Socios/RelatorioSocio/index.vue')
const CentralDeNotificacoes = () => import('../views/CentralDeNotificacoes/index.vue')
// const PlanosDeCentrosDeCusto = () => import('../views/PlanosDeCentrosDeCusto/index.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/inicio',
    name: 'Aba Inicial',
    component: abaInicio
  },
  {
    path: '/cadastros/:name?',
    name: 'Cadastros',
    component: Cadastros
  },
  {
    path: '/contabil/:name?/:idDoPlano?/:carregarPaginaJaExibindoModalListagemDePlanos?',
    name: 'Contabil',
    component: Contabil,
    props: true
  },
  // {
  //   path: '/planos-de-centro-de-custo',
  //   name: 'PlanoDeCentroDeCusto',
  //   component: PlanosDeCentrosDeCusto
  // },
  // {
  //   path: '/centros-de-custo',
  //   name: 'CentroDeCusto',
  //   beforeEnter: (to, from, next) => {
  //     if (!to.params.id) {
  //       next('/planos-de-centro-de-custo')
  //     } else {
  //       next()
  //     }
  //   }
  // },
  {
    path: '/empresa/:id?',
    name: 'Empresa',
    component: Empresa
  },
  {
    path: '/cadastros/empresa/:id?',
    name: 'Empresa',
    component: Empresa
  },
  {
    path: '/socio',
    name: 'Socios',
    component: Socios
  },
  {
    path: '/indexador',
    name: 'Indexador',
    component: Indexador
  },
  {
    path: '/indexadorCotacao/:idIndexador',
    name: 'IndexadorCotacao',
    component: IndexadorCotacao,
    props: true
  },
  {
    path: '/feriados',
    name: 'Feriados',
    component: Feriados
  },
  {
    path: '/irrf',
    name: 'Irrf',
    component: Irrf
  },
  {
    path: '/planos-de-contas',
    name: 'PlanoDeContas',
    component: PlanosDeContas
  },
  {
    path: '/socio',
    name: 'Sócio',
    component: Socios
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: Home }
  },
  {
    path: '/configuracoes/:name?',
    name: 'Configuracoes',
    component: Configuracoes
  },
  {
    path: '/acessar/:jwt?',
    name: 'Acessar',
    component: Home
  },
  {
    path: '/erroAcesso',
    name: 'Erro Acesso',
    component: ErroAcesso
  },
  {
    path: '/socios/relatorios/',
    name: 'RelatorioSocios',
    component: ImpressaoRelatorioSocios
  },
  {
    path: '/central-de-notificacoes',
    name: 'CentralDeNotificacoes',
    component: CentralDeNotificacoes
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router
