<template>
  <div id="quickView" class="alt-modal alt-is-active" :style="styleAltModal">
    <div class="alt-modal-card" :style="styleModalCard">
      <header class="primary-header modal-card-head" style="padding: 8px">
        <h2 class="modal-card-title" style="text-align: left; color: white; font-size: 1rem">
          {{ tituloTela }}
        </h2>
        <span v-if="minimizavel" class="icon-text">
          <span class="icon">
            <i
              :class="!minimizado ? 'fas fa-minus' : 'far fa-window-maximize'"
              style="color: white; padding-right: 1rem"
              @click="$emit('minimizarQuickView')"
            >
            </i>
          </span>
        </span>
        <button class="delete" @click="$emit('fecharQuickView')"></button>
      </header>
      <section v-show="!minimizado" class="modal-card-body" style="overflow-y: auto; overflow-x: hidden">
        <slot></slot>
      </section>
      <footer v-show="!minimizado" class="modal-card-foot is-justify-content-flex-end">
        <div class="buttons is-right">
          <Checkbox
            v-if="!ocultarBotaoContinuarCadastrando"
            :key="keyCheckBox"
            v-model="$parent.paramentrosQuickView.continuarCadastrando"
            caption="Continuar cadastrando"
            data-group="continuar-cadastrando-quickview"
            class="mr-2"
          />
          <Button
            v-if="!ocultarBotaoGravar"
            id="quickView-btn-gravar"
            :class="botaoSalvarPrimary ? 'is-primary' : ''"
            :caption="tituloBotaoGravar"
            :title="atalhoBotaoGravar"
            :disabled="botaoGravarDesativado"
            data-group="btn-gravar-quickview"
            class="enter-allowed"
            @click="$emit('salvarQuickView')"
          />
          <Button
            v-if="!ocultarBotaoFechar"
            data-group="btn-cancelar-quickview"
            :caption="tituloBotaoFechar"
            :title="atalhoBotaoFechar"
            class="prevent-tab"
            @click="$emit('fecharQuickView')"
          />
        </div>
      </footer>
    </div>
  </div>
  <!-- <div id="quickView" class="alt-modal alt-is-active">
    <div class="alt-modal-card-head">
      <header class="modal-card-head" style="background-color: #a82339; padding: 8px">
        <h2 class="modal-card-title" style="text-align: left; color: white; font-size: 1rem">
          {{ tituloTela }}
        </h2>
        <span class="icon-text">
          <span class="icon">
            <i
              :class="!minimizado ? 'fas fa-minus' : 'far fa-window-maximize'"
              style="color: white; padding-right: 1rem"
              @click="$emit('minimizarQuickView')"
            >
            </i>
          </span>
        </span>
        <button class="delete" @click="$emit('fecharQuickView')"></button>
      </header>
      <section
        v-show="!minimizado"
        class="modal-card-body"
        :style="{ height: alturaQuickView }"
        style="padding-left: unset; padding-right: 0rem; padding-top: 0rem"
      >
        <slot></slot>
      </section>
      <footer v-show="!minimizado" class="modal-card-foot is-justify-content-flex-end">
        <div class="buttons is-right">
          <Checkbox
            v-if="!ocultarBotaoContinuarCadastrando"
            :key="keyCheckBox"
            v-model="$parent.paramentrosQuickView.continuarCadastrando"
            caption="Continuar cadastrando"
            class="mr-2"
          />
          <Button
            v-if="!ocultarBotaoGravar"
            id="quickView-btn-gravar"
            :class="botaoSalvarPrimary ? 'is-primary' : ''"
            caption="Gravar"
            @click="$emit('salvarQuickView')"
          />
          <Button v-if="!ocultarBotaoFechar" :caption="tituloBotaoFechar" @click="$emit('fecharQuickView')" />
        </div>
      </footer>
    </div>
  </div> -->
</template>

<script>
import Button from '@alterdata/component-vue/Button'
import Checkbox from '@alterdata/component-vue/Checkbox/index.vue'

export default {
  name: 'QuickView',
  components: {
    Button,
    Checkbox
  },

  props: {
    // eslint-disable-next-line vue/require-default-prop
    tituloTela: String,
    tituloBotaoFechar: {
      type: String,
      default: 'Fechar'
    },
    tituloBotaoGravar: {
      type: String,
      default: 'Gravar'
    },
    atalhoBotaoGravar: {
      type: String,
      default: ''
    },
    atalhoBotaoFechar: {
      type: String,
      default: ''
    },
    botaoGravarDesativado: {
      type: Boolean,
      default: false
    },
    alturaQuickView: {
      type: String,
      default: '81vh'
    },
    larguraQuickView: {
      type: String,
      default: ''
    },
    ocultarBotaoFechar: Boolean,
    ocultarBotaoGravar: Boolean,
    ocultarBotaoContinuarCadastrando: Boolean,
    minimizado: Boolean,
    botaoSalvarPrimary: Boolean,
    pContinuarCadastrando: Boolean,
    minimizavel: {
      type: Boolean,
      default: true
    }
  },

  emits: ['continuarCadastrando', 'minimizarQuickView', 'fecharQuickView', 'salvarQuickView'],

  data () {
    return {
      continuarCadastrando: false,
      keyCheckBox: 0
    }
  },
  computed: {
    styleModalCard () {
      if (this.larguraQuickView !== '') {
        return [`width: ${this.larguraQuickView}`, 'min-width: none']
      } else {
        return []
      }
    },
    styleAltModal () {
      const style = []
      if (this.larguraQuickView === '') {
        style.push('max-width: 40% !important')
      }
      if (this.minimizado) {
        style.push('max-height: 8% !important; bottom: 7% !important')
      }
      style.push('box-shadow: 13px 17px 53px 5px #070707;')
      return style
    }
  },

  watch: {
    pContinuarCadastrando (val) {
      this.continuarCadastrando = val
    }
  },

  mounted () {
    setTimeout(() => this.keyCheckBox++, 10)
  }
}
</script>

<style lang="scss" scoped>
@import '@alterdata/theme-bulma';
@import '@packonline/packonline-reutilizaveis-frontend/src/assets/sass/_colors.scss';

.alt-modal.alt-is-active {
  @extend .modal, .is-active;
  height: 94% !important;
  left: auto !important;
  right: 0 !important;
  top: auto !important;
  bottom: 0 !important;
}

.primary-header {
  background-color: $primary-header;
}

.alt-modal-card {
  @extend .modal-card;
  max-width: 100% !important;
  min-height: 100% !important;
}
</style>
