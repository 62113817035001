<template>
  <div class="dropdown is-right is-active" @focusout="this.verificarSeHaModalAtivo()" tabindex="0">
    <div class="dropdown-trigger">
      <a style="color: white" @click="this.abrirFecharAjuda">
        <span class="icon is-medium">
          <em class="fas fa-question-circle"></em>⠀Ajuda
          <em class="fas fa-angle-down ml-2"></em>
        </span>
      </a>
    </div>
    <div v-if="this.ajudaVisivel" class="dropdown-menu" id="dropdown-menu-config" role="menu">
      <div class="dropdown-content" style="padding: 5px">
        <div class="dropdown-item">
          <div class="columns">
            <div class="column">
              <div class="columns is-multiline">
                <a @click="this.direcionarParaAjuda" target="_blank" class="column is-12 mt-2">Ajuda</a>
                <a @click="this.direcionarParaNovidades" target="_blank" class="column is-12">Novidades da versão</a>
                <a @click="this.sobreVisivel = true" class="column is-12">Sobre o sistema</a>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <footer class="is-flex is-justify-content-center pb-3">
          <a style="color:blue" @click="this.ajudaVisivel = false">Mostrar menos</a>
        </footer>
      </div>
    </div>
  </div>

  <ModalSobreOSistema
    v-if="this.sobreVisivel"
    @cancel="this.sobreVisivel = false"
    :urlApi="this.urlApi"
    :ambiente="this.ambiente"
    :versaoDoFrontend="this.versao"
    :aplicacao="aplicacao"
  />

</template>

<script>
import ModalSobreOSistema from '@packonline/packonline-reutilizaveis-frontend/components/ModalSobreOSistema.vue'
import { env } from '@/env.js'

export default {
  data () {
    return {
      ajudaVisivel: false,
      sobreVisivel: false,
      urlApi: env.urlApi,
      ambiente: process.env.NODE_ENV,
      versao: require('../../../../package.json').version,
      aplicacao: require('../../../../package.json').name
    }
  },
  components: { ModalSobreOSistema },
  methods: {
    abrirFecharAjuda () {
      this.ajudaVisivel = !this.ajudaVisivel
    },
    verificarSeHaModalAtivo () {
      const modal = document.querySelectorAll('.modal')[0]
      if (!modal) {
        this.ajudaVisivel = false
      }
    },
    direcionarParaNovidades () {
      window.open('https://ajuda.alterdata.com.br/x/JcEfC', '_blank').focus()
    },
    direcionarParaAjuda () {
      window.open('https://ajuda.alterdata.com.br/x/8cAfC', '_blank').focus()
    }
  },
  updated () {
    if (this.ajudaVisivel) {
      const dropdown = document.querySelector('#dropdown-menu-config')
      dropdown.focus()
    }
  }
}
</script>

<style scoped>
a {
  color: black
}

*,
*:focus,
*:hover {
  outline: none
}

</style>
