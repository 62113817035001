/**
 * ENVironment settings
 */
export const env = {
  cepApi: process.env.VUE_APP_CEP_API,
  env: process.env.NODE_ENV,
  domain: 'alterdata.com.br',
  urlApi: process.env.VUE_APP_URL_API,
  phd: process.env.VUE_APP_URL_PHD,
  msPhd: process.env.VUE_APP_URL_API_PHD,
  contabil: process.env.VUE_APP_URL_CONT,
  msContabil: process.env.VUE_APP_URL_API_CONT,
  dp: process.env.VUE_APP_URL_DP,
  msDp: process.env.VUE_APP_URL_API_DP

  // Para rodar localmente
  // domain: 'localhost',
  // phd: 'http//localhost:5000',
  // urlApi: 'http://localhost:8082',
  // contabil: 'http://localhost:5001',
  // msContabil: 'http://localhost:8081',
  // msPhd: 'http://localhost:8082',
  // dp: 'http://localhost:5002',
  // msDp: 'http://localhost:8080'
}
